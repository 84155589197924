import md5 from "md5";

class ConfigCache {
  __name = "dev-dailylawtimes";
  __version = 1;
  __store = null;
  __storeFields = [
    "Property1",
    "Property2",
    "Property3",
    "PropertyList1",
    "PropertyList2",
    "PropertyList3",
    "Category",
  ];
  __lc = ["en", "hi", "ar"];
  constructor(store) {
    this.__store = store;
  }

  async init() {
    try {
      await new Promise((resolve, reject) => {
        const request = indexedDB.open(
          md5(`${this.__name}-${this.__store}`),
          this.__version
        );
        request.onupgradeneeded = (event) => {
          this.__db = event.target.result;
          this.__storeFields.forEach((element) => {
            if (!this.__db.objectStoreNames.contains(element)) {
              const objectstore = this.__db.createObjectStore(element, {
                keyPath: "id",
              });
              objectstore.createIndex("store", "store", { unique: false });
            }
          });
        };
        request.onsuccess = (event) => {
          this.__db = event.target.result;
          resolve();
        };

        request.onerror = (event) => {
          console.error("Database failed to initialize:", event.target.error);
          reject();
        };
      });
    } catch (error) {}
  }
  async addData(id, data, type, lc = "en") {
    if (!this.__db) await this.init();
    try {
      await new Promise((resolve, reject) => {
        const transaction = this.__db.transaction([type], "readwrite");
        const objectStore = transaction.objectStore(type);
        const request = objectStore.add({
          id: `${id}-${lc}-${this.__store}`,
          data: data,
          store: `${this.__store}-${lc}`,
        });

        request.onsuccess = () => {
          resolve();
        };
        request.onerror = (event) => {
          console.error("Error adding pair:", event.target.error);
          reject();
        };
      });
    } catch (error) {}
  }

  async addAll(datalist, type, lc = "en") {
    if (!this.__db) await this.init();

    const transaction = this.__db.transaction([type], "readwrite");
    const objectStore = transaction.objectStore(type);
    const dataPromises = datalist.forEach((item) => {
      return new Promise((resolve, reject) => {
        const request = objectStore.add({
          id: `${item.id}-${lc}`,
          data: item,
          store: `${this.__store}-${lc}`,
        });

        request.onsuccess = () => {
          resolve();
        };
        request.onerror = (event) => {
          console.error("Error adding pair:", event.target.error);
          reject();
        };
      });
    });

    try {
      await Promise.all(dataPromises);
    } catch (error) {}
  }
  async getData(id, type, lc = "en") {
    if (!this.__db) await this.init();
    try {
      return await new Promise((resolve, reject) => {
        const transaction = this.__db.transaction([type], "readonly");
        const objectStore = transaction.objectStore(type);
        const request = objectStore.get(`${id}-${lc}-${this.__store}`);

        request.onsuccess = (event) => {
          const results = event.target.result;
          if (results.length > 0) {
            resolve(results);
          } else {
            resolve(null);
          }
        };

        request.onerror = (event) => {
          reject(event.target.error);
        };
      });
    } catch (error) {
      return null;
    }
  }
  async getAll(type, lc = "en") {
    if (!this.__db) await this.init();

    try {
      return await new Promise((resolve, reject) => {
        const transaction = this.__db.transaction([type], "readonly");
        const objectStore = transaction.objectStore(type);
        const index = objectStore.index("store");
        const request = index.getAll(`${this.__store}-${lc}`);

        request.onsuccess = (event) => {
          const results = event.target.result;
          if (results.length > 0) {
            resolve(results);
          } else {
            resolve([]);
          }
        };

        request.onerror = (event) => {
          reject(event.target.error);
        };
      });
    } catch (error) {
      return [];
    }
  }
  async updateData(id, data, type, lc = "en") {
    if (!this.__db) await this.init();
    try {
      return await new Promise((resolve, reject) => {
        const transaction = this.__db.transaction([type], "readwrite");
        const objectStore = transaction.objectStore(type);
        const request = objectStore.put({
          id: `${id}-${lc}-${this.__store}`,
          data: data,
          store: `${this.__store}-${lc}`,
        });

        request.onsuccess = () => {
          resolve();
        };

        request.onerror = (event) => {
          console.error("Error updating pair:", event.target.error);
          reject(event.target.error);
        };
      });
    } catch (error) {}
  }
  async refetch(type, datalist,filter) {
    if (!this.__db) await this.init(); 
    for (const key in datalist) {
      if (Object.prototype.hasOwnProperty.call(datalist, key)) {
        const item = datalist[key];
        const id = item.id; 
        const data = filter(item); 
        for (let index = 0; index < this.__lc.length; index++) {
          const lc = this.__lc[index];
          try {
            await new Promise((resolve, reject) => {
              const transaction = this.__db.transaction([type], "readwrite");
              const objectStore = transaction.objectStore(type);
              const request = objectStore.put({
                id: `${id}-${lc}-${this.__store}`,
                data: data,
                store: `${this.__store}-${lc}`,
              });

              request.onsuccess = () => {
                resolve();
              };

              request.onerror = (event) => {
                console.error("Error updating pair:", event.target.error);
                reject(event.target.error);
              };
            });
          } catch (error) {}
        }
      }
    }
  }
  async deleteData(id, type, lc = "en") {
    if (!this.__db) await this.init();
    try {
      return await new Promise((resolve, reject) => {
        const transaction = this.__db.transaction([type], "readwrite");
        const objectStore = transaction.objectStore(type);
        const request = objectStore.delete(`${id}-${lc}-${this.__store}`);
        request.onsuccess = () => {
          resolve();
        };

        request.onerror = (event) => {
          console.error("Error deleting  pair:", event.target.error);
          reject(event.target.error);
        };
      });
    } catch (error) {}
  }
  async deleteAll(type) {
    if (!this.__db) await this.init();
    try {
      return await new Promise((resolve, reject) => {
        const transaction = this.__db.transaction([type], "readwrite");
        const objectStore = transaction.objectStore(type);

        const request = objectStore.clear();

        request.onsuccess = () => resolve();
        request.onerror = (event) => reject(event.target.error);
      });
    } catch (error) {}
  }
}
export default ConfigCache;
